import React, { useEffect } from 'react'

import {
  useUser,
  PackageCard,
  LeshenPhoneCTA,
  AvailabilityForm,
  ModalWithState,
} from '@leshen/gatsby-theme-leshen'

import {
  VariableContent,
  Columns,
  Stack,
  scrollTo,
  Brandy,
  Typography,
} from '@leshen/ui'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

const ZipPackageSection = ({ spanish }) => {
  const { userData, userLocation, setUserData, setZipLocation } = useUser()

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData.loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if aff_unique3 exists in the url.
    // that param is something that consumer may send to us and if so, we want to run the
    // zip logic on that zipcode (if userData doesn't already exist)
    const zipcode = userLocation?.zipCode

    if (zipcode && zipcode.length === 5 && !userData) {
      ;(async () => {
        const { packageSectionData } = await zipLogic(zipcode, spanish)

        setZipLocation(zipcode)

        setUserData((previousUserData) => ({
          ...previousUserData,
          loading: false,
          hughesNetPackages: packageSectionData || false,
        }))
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [
    userData,
    setUserData,
    availablePlans,
    setAvailablePlans,
    userLocation,
    setZipLocation,
    spanish,
  ])

  /**
   * This should be handled in the callback for the availability
   * lookup, but because that is handled through shadowing, and not
   * through passing down props from here, it is not feasible. I
   * decided it would be best to have the scrollTo and it's target
   * in the same file after considering all this.
   */
  useEffect(() => {
    if (availablePlans && availablePlans?.hughesNetPackages?.length >= 1) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                {spanish
                  ? 'Buscando paquetes en tu zona.'
                  : 'Searching packages in your area.'}
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {availablePlans?.hughesNetPackages?.length >= 1 &&
        !arePackagesLoading && (
          <VariableContent
            className="packages"
            alignMainContent="center"
            mainContent={
              <div>
                {userLocation.city && userLocation.zipCode && (
                  <>
                    <Typography variant="h2">
                      <strong>
                        Hughesnet Satellite Internet: View Plans and Details
                        Below
                      </strong>
                    </Typography>
                    <Typography variant="body">
                      Showing best plans in {userLocation.city},{' '}
                      {userLocation.state.abbreviation}
                      {''}, {userLocation.zipCode}
                    </Typography>
                  </>
                )}
              </div>
            }
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Columns>
                {availablePlans.hughesNetPackages.map((data) => (
                  <PackageCard
                    label={data.label}
                    packageSectionData={{
                      ...data.brandy,
                    }}
                    content={
                      // Disabled lint line due to Gatsby api named variable
                      // eslint-disable-next-line no-underscore-dangle
                      <LeshenPhoneCTA color="primary">
                        {spanish ? 'Llama al' : 'Call'}
                      </LeshenPhoneCTA>
                    }
                    key={data.label}
                    modal={
                      <ModalWithState
                        modalText="See offer details"
                        heading={data?.modalData?.heading}
                      >
                        {data?.modalData?.legal?.map((legalData) => (
                          <Brandy
                            key={legalData.reference}
                            text={legalData.text}
                            variant="legal"
                          />
                        ))}
                      </ModalWithState>
                    }
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
        )}

      {availablePlans?.hughesNetPackages?.length === 0 &&
        !arePackagesLoading &&
        userData && (
          <VariableContent
            alignMainContent="center"
            mainContent={
              <div>
                <Typography variant="h2">
                  Your address is outside of our service area.
                </Typography>
                <Typography variant="h3">
                  Try another zip code to find service.
                </Typography>
                <AvailabilityForm
                  buttonText="Check Availability"
                  buttonColor="primary"
                  placeholderText="Enter ZIP"
                  buttonBackgroundColor="black"
                />
              </div>
            }
          />
        )}

      {/**
       * If userData is not null, and its `loading` property is set to false
       * by availabilitySubmit, then a fetch request has just completed.
       * That in combination with having no beam package data should mean
       * that there are no packages for the location the user searched.
       */}
      {availablePlans &&
        !availablePlans.loading &&
        availablePlans?.hughesNetPackages === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  We&apos;re having trouble locating service options for your
                  area.
                </Typography>
                <p>
                  Give us a call and one of our Internet specialists can help
                  get you connected!
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

export default ZipPackageSection
